<template>
  <div>
    <div v-if="disabled" class="flex justify-center mt-8">
      {{ $t("app.view_lesson_msg") }}
    </div>

    <div class="flex justify-center">
      <VButton
        class="btn-primary mt-4"
        :disabled="disabled"
        :label="buttonLabel"
        @click="$emit('click')"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, inject, computed } from "vue";
// Components
import VButton from "@/components/VButton";

export default {
  components: {
    VButton
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    isCompleted: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click"],
  setup(props) {
    // Constants
    const COUNTDOWN = 10000;
    const INCREMENT = 1000;

    // Inject
    // eslint-disable-next-line
    const moment = inject("moment");

    // Data
    const disabled = ref(false);
    const time = ref(COUNTDOWN);
    const timer = ref(null);

    // Computed
    const buttonLabel = computed(() => {
      if (time.value <= 0) {
        return props.label;
      }

      return `${props.label} (${moment.utc(time.value).format("mm:ss")})`;
    });

    // Methods
    const endCounter = () => {
      clearInterval(timer.value);
      time.value = 0;
    };

    // Lifecycle Hooks
    onMounted(() => {
      timer.value = setInterval(() => {
        time.value -= INCREMENT;

        if (time.value <= 0) {
          disabled.value = false;
          endCounter();
        }
      }, INCREMENT);
    });

    // Watcher
    watch(
      () => props.isCompleted,
      () => {
        disabled.value = !props.isCompleted;

        if (!disabled.value) {
          endCounter();
        }
      },
      {
        immediate: true
      }
    );

    return {
      buttonLabel,
      disabled
    };
  }
};
</script>
