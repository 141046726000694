<template>
  <LessonType :title="$t('app.download_slides')" :content="fileName">
    <LessonButton
      :label="$t('app.download')"
      :is-completed="isCompleted"
      @click="onClickDownload"
    />
  </LessonType>
</template>

<script>
import { computed } from "vue";
// Composables
import useDownload from "@/composables/useDownload";
// Components
import LessonType from "./LessonType";
import LessonButton from "./LessonButton";

export default {
  components: {
    LessonType,
    LessonButton
  },
  props: {
    content: {
      type: Object,
      default: () => ({})
    },
    isCompleted: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // Computed
    const fileName = computed(() => props.content?.name);
    const fileURL = computed(() => props.content?.download_path);

    // Composables
    const { downloadFile } = useDownload();

    // Methods
    const onClickDownload = () => {
      downloadFile(fileURL.value);
    };

    return {
      fileName,
      onClickDownload
    };
  }
};
</script>
