<template>
  <LessonType :title="$t('app.redirect_url')" :content="content">
    <div v-if="src" class="flex justify-center mt-4">
      <iframe
        type="text/html"
        width="640"
        height="360"
        :src="src"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
      />
    </div>

    <LessonButton
      v-if="!src"
      :label="$t('app.watch')"
      :is-completed="isCompleted"
      @click="onClickWatch"
    />
  </LessonType>
</template>

<script>
import { ref, computed } from "vue";
// Components
import LessonType from "./LessonType";
import LessonButton from "./LessonButton";

export default {
  components: {
    LessonType,
    LessonButton
  },
  props: {
    content: {
      type: String,
      default: ""
    },
    isCompleted: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // Data
    const src = ref("");

    // Computed
    const url = computed(() => props.content);
    const isYoutube = computed(() => {
      const regex = /(youtube.com|youtu.be)/;
      return regex.test(url.value);
    });
    const youtubeSrc = computed(() => {
      const regex = /v=(.*)&/;
      const id = url.value.match(regex)[1];

      return `https://www.youtube.com/embed/${id}`;
    });
    const isVimeo = computed(() => {
      const regex = /vimeo.com/;
      return regex.test(url.value);
    });
    const vimeoSrc = computed(() => {
      const regex = /https:\/\/vimeo.com\/(.*)/;
      const id = url.value.match(regex)[1];

      return `https://player.vimeo.com/video/${id}`;
    });

    // Methods
    const onClickWatch = () => {
      if (isYoutube.value) {
        src.value = youtubeSrc.value;
        return;
      }

      if (isVimeo.value) {
        src.value = vimeoSrc.value;
        return;
      }

      window.open(url.value, "_blank");
    };

    return {
      src,
      onClickWatch
    };
  }
};
</script>
