<template>
  <VCard>
    <template #content>
      <component
        :is="component"
        :content="content"
        :is-completed="isCompleted"
      />
    </template>
  </VCard>
</template>

<script>
import { computed } from "vue";
// Composables
import useOptions from "@/composables/useOptions";
import useTexts from "@/composables/useTexts";
// Components
import VCard from "@/components/VCard";
import LessonDocument from "./contentType/LessonDocument";
import LessonSlides from "./contentType/LessonSlides";
import LessonAudio from "./contentType/LessonAudio";
import LessonVideo from "./contentType/LessonVideo";
import LessonURL from "./contentType/LessonURL";
import LessonLiveLecture from "./contentType/LessonLiveLecture";

export default {
  components: {
    VCard,
    LessonDocument,
    LessonSlides,
    LessonAudio,
    LessonVideo,
    LessonURL,
    LessonLiveLecture
  },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    // Composables
    const { ALL_OPTIONS } = useOptions();
    const { getText } = useTexts();

    // Computed
    const lesson = computed(() => props.resource?.lesson);
    const texts = computed(() => lesson.value?.texts);
    const type = computed(() => lesson.value?.type);
    const completed_on = computed(() => props.resource?.completed_on);
    const isCompleted = computed(() => !!completed_on.value);
    const content = computed(() => {
      const types = {
        [ALL_OPTIONS.DOCUMENT.value]: "content_file",
        [ALL_OPTIONS.VIDEO.value]: "content_location",
        [ALL_OPTIONS.URL.value]: "content_location",
        [ALL_OPTIONS.AUDIO.value]: "content_file",
        [ALL_OPTIONS.LIVE_LECTURE.value]: "content_location",
        [ALL_OPTIONS.SLIDES.value]: "content_file"
      };
      const fallbacks = {
        [ALL_OPTIONS.DOCUMENT.value]: {},
        [ALL_OPTIONS.VIDEO.value]: "",
        [ALL_OPTIONS.URL.value]: "",
        [ALL_OPTIONS.AUDIO.value]: {},
        [ALL_OPTIONS.LIVE_LECTURE.value]: "",
        [ALL_OPTIONS.SLIDES.value]: {}
      };

      return getText(texts.value, types[type.value]) || fallbacks[type.value];
    });
    const component = computed(() => {
      const types = {
        [ALL_OPTIONS.DOCUMENT.value]: "LessonDocument",
        [ALL_OPTIONS.VIDEO.value]: "LessonVideo",
        [ALL_OPTIONS.URL.value]: "LessonURL",
        [ALL_OPTIONS.AUDIO.value]: "LessonAudio",
        [ALL_OPTIONS.LIVE_LECTURE.value]: "LessonLiveLecture",
        [ALL_OPTIONS.SLIDES.value]: "LessonSlides"
      };

      return types[type.value];
    });

    return {
      content,
      component,
      isCompleted
    };
  }
};
</script>
