<template>
  <VCard>
    <template #title>
      <div class="capitalize">
        {{ $t("app.complete_lesson") }}
      </div>
    </template>

    <template #content>
      <div class="mb-4">
        {{ msg }}
      </div>

      <div class="flex items-start">
        <VText
          v-model="v.password.$model"
          :placeholder="placeholder"
          type="password"
          class="w-full mr-2"
          :errors="v.password.$errors"
          @keyup.enter="onClickSave"
        />

        <VButton
          :is-loading="isLoading"
          class="btn-primary"
          :label="$t('app.submit')"
          @click="onClickSave"
        />
      </div>
    </template>
  </VCard>
</template>

<script>
import { inject, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/i18n/i18n-validators";
// Composables
import useForm from "@/composables/useForm";
import useVerifyPassword from "@/composables/useVerifyPassword";
import useRequest from "@/composables/useRequest";
// Components
import VCard from "@/components/VCard";
import VText from "@/components/inputs/VText";
import VButton from "@/components/VButton";

export default {
  components: {
    VCard,
    VText,
    VButton
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  emits: ["refresh"],
  setup(props, context) {
    // Misc
    const { t } = useI18n();
    const toast = inject("toast");

    // Data
    const isLoading = ref(false);
    const value = ref({
      password: ""
    });
    const rules = ref({
      password: { required }
    });

    // Composables
    const { v, onSave } = useForm(props, context, {
      value,
      rules
    });
    const { request, isSuccess } = useRequest();
    const { placeholder, verify, verify_by } = useVerifyPassword();

    // Computed
    const msg = computed(() => {
      const msgs = {
        password: t("app.complete_lesson_password_msg"),
        pin: t("app.complete_lesson_pin_msg")
      };

      return msgs[verify_by.value] ?? "";
    });

    // Methods
    const onClickSave = async () => {
      isLoading.value = true;

      const data = await onSave();

      if (!data) {
        isLoading.value = false;
        return;
      }

      const isVerified = await verify(data.password);

      if (!isVerified) {
        isLoading.value = false;
        return;
      }

      const acknowledgeResponse = await acknowledge();
      const status = acknowledgeResponse?.payload?.message?.status;

      if (isSuccess(acknowledgeResponse)) {
        toast.show(t("app.acknowledge_lesson_success"), status);
      } else {
        toast.show(t("app.acknowledge_lesson_failure"), status);
      }

      value.value.password = "";
      await v.value.$reset();

      isLoading.value = false;

      context.emit("refresh");
    };

    const acknowledge = async () => {
      return await request({
        endpoint: "learning.lessons.acknowledge",
        pathParams: {
          id: props.id
        }
      });
    };

    return {
      msg,
      isLoading,
      placeholder,
      onClickSave,
      // useForm
      v,
      onSave
    };
  }
};
</script>
