<template>
  <LessonType :title="$t('app.redirect_url')" :content="content">
    <LessonButton
      :label="$t('app.redirect')"
      :is-completed="isCompleted"
      @click="onClickRedirect"
    />
  </LessonType>
</template>

<script>
// Components
import LessonType from "./LessonType";
import LessonButton from "./LessonButton";

export default {
  components: {
    LessonType,
    LessonButton
  },
  props: {
    content: {
      type: String,
      default: ""
    },
    isCompleted: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // Methods
    const onClickRedirect = () => {
      const url = props.content?.replace(/^.*:\/\//i, "");
      window.open(`//${url}`, "_blank");
    };

    return {
      onClickRedirect
    };
  }
};
</script>
