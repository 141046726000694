<template>
  <LessonType :title="$t('app.download_audio')" :content="fileName">
    <div class="flex justify-center mt-4">
      <audio controls autoplay>
        <source v-if="src" :src="src" />
      </audio>
    </div>

    <LessonButton
      :label="$t('app.listen')"
      :is-completed="isCompleted"
      @click="onClickListen"
    />
  </LessonType>
</template>

<script>
import { ref, computed } from "vue";
// Composables
import useDisplay from "@/composables/useDisplay";
// Components
import LessonType from "./LessonType";
import LessonButton from "./LessonButton";

export default {
  components: {
    LessonType,
    LessonButton
  },
  props: {
    content: {
      type: Object,
      default: () => ({})
    },
    isCompleted: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // Composables
    const { getAvatarURL } = useDisplay();

    // Computed
    const fileName = computed(() => props.content?.name);
    const fileSrc = computed(() => getAvatarURL(props.content?.view_path));

    // Data
    const src = ref("");

    // Methods
    const onClickListen = () => {
      src.value = fileSrc.value;
    };

    return {
      fileName,
      src,
      onClickListen
    };
  }
};
</script>
