<template>
  <VAlert v-if="!content" :text="$t('app.lesson_no_content')" />

  <div v-else>
    <div class="flex justify-center mb-4">
      <SendIcon class="w-10 h-10 text-theme-1" />
    </div>

    <div class="flex justify-center text-xl uppercase font-medium mb-2">
      {{ title }}
    </div>

    <div class="flex justify-center font-light">
      {{ content }}
    </div>

    <slot />
  </div>
</template>

<script>
// Components
import VAlert from "@/components/VAlert";

export default {
  components: {
    VAlert
  },
  props: {
    content: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    }
  }
};
</script>
