<template>
  <div>
    <div class="flex justify-between items-center">
      <VTitle class="flex justify-baseline items-center route-title">
        {{ $t("app.lessons", 2) }}

        <VHint class="ml-4 text-base">
          {{ $t("hints.learning_lessons") }}
        </VHint>
      </VTitle>

      <div v-if="isCompleted" class="flex">
        <FeedbackButton
          :id="data?.lesson?.id"
          :type="feedbackRatingTypes.LESSON"
          class="mr-3"
        />

        <RatingButton
          :id="id"
          :name="name"
          :type="feedbackRatingTypes.LESSON"
        />
      </div>
    </div>

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <div v-else>
      <div class="grid grid-cols-3 gap-8">
        <div class="col-span-3 lg:col-span-1">
          <LessonCard is-view :resource="data" />

          <Versions
            v-if="isInstructor"
            class="mt-8"
            :route="route"
            :versions="data?.lesson?.versions"
          />
        </div>

        <div class="col-span-3 lg:col-span-2">
          <LessonAcknowledgements
            v-if="!data?.completed_on"
            :id="id"
            class="mb-8"
            @refresh="getData"
          />

          <LessonContent :resource="data" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
import useLesson from "@/composables/useLesson";
import useOptions from "@/composables/useOptions";
import useRequest from "@/composables/useRequest";
// Components
import LessonCard from "@/views/learning/lessons/LessonCard";
import LessonAcknowledgements from "@/views/learning/lessons/LessonAcknowledgements";
import LessonContent from "@/views/learning/lessons/LessonContent";
import VTitle from "@/components/VTitle";
import Versions from "@/components/templates/Versions";
import FeedbackButton from "@/components/FeedbackButton";
import RatingButton from "@/components/RatingButton";
import VHint from "@/components/VHint";
// Constants
import feedbackRatingTypes from "@/constants/feedbackRatingTypes";

export default {
  components: {
    VTitle,
    LessonCard,
    LessonContent,
    LessonAcknowledgements,
    Versions,
    FeedbackButton,
    RatingButton,
    VHint
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();
    const store = useStore();

    // Composables
    const { request } = useRequest();
    const { getText } = useTexts();
    const { route } = useLesson();
    const { ALL_OPTIONS } = useOptions();

    // Data
    const data = ref({});
    const isLoading = ref(false);

    // Computed
    const name = computed(() => getText(data.value?.lesson?.texts, "name"));
    const status = computed(() => data.value?.status);
    const isInstructor = computed(() => store.getters["user/isInstructor"]);
    // eslint-disable-next-line
    const isCompleted = computed(() => status.value === ALL_OPTIONS.COMPLETED.value);
    const documentTitle = computed(() => {
      const title = `${t("app.lessons", 2)} - ${t("app.learning")}`;

      return name.value ? `${name.value} - ${title}` : title;
    });

    // Methods
    const getData = async () => {
      isLoading.value = true;

      const response = await request({
        endpoint: "learning.lessons.read",
        pathParams: {
          id: props.id
        },
        showToaster: false
      });

      data.value = response?.payload?.data ?? {};

      isLoading.value = false;
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      isCompleted,
      name,
      data,
      isLoading,
      documentTitle,
      isInstructor,
      getData,
      feedbackRatingTypes,
      // useLesson
      route
    };
  }
};
</script>
