<template>
  <ReadPage :id="lessonId" />
</template>

<script>
// Components
import ReadPage from "@/views/learning/lessons/ReadPage";

export default {
  components: {
    ReadPage
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    lessonId: {
      type: [String, Number],
      required: true
    }
  }
};
</script>
