<template>
  <LessonType :title="$t('app.venue')" :content="content" />
</template>

<script>
// Components
import LessonType from "./LessonType";

export default {
  components: {
    LessonType
  },
  props: {
    content: {
      type: String,
      default: ""
    }
  }
};
</script>
